import React from "react";
import PropTypes from "prop-types";

const TextareaInput = ({
  name,
  title,
  type,
  value,
  error,
  handleChange,
  placeholder,
  className,
}) => {
 
  return (
    <div className=" mx-2 mb-2">
      {title && (
        <label className="d-flex align-items-center fs-12 fw-semibold mb-2">
          <span className="required">{title}</span>
        </label>
      )}

      <textarea
        className="form-control"
        className={className ? className : "form-control  form-control-solid"}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          handleChange(e);
        }}
        rows="3"
      ></textarea>

      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

TextareaInput.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default TextareaInput;
