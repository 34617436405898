import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const listCommission = createAsyncThunk("user/listCommission", async (payload) => {

  console.log(8, payload)
  const response = await api.post("admin/commission/list", payload);
 
  return response.data;
});

const listCommissionSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listCommission.pending, (state) => {
        state.loading = true;
      })
      .addCase(listCommission.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(listCommission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default listCommissionSlice.reducer;
