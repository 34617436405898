import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextInput from "./text-input";
import { Button } from "../components/index";

const initialData = {
  1: { 0: 0.0, 1: 3.96 },
  2: { 0: 0.0, 1: 1.9, 2: 4.5 },
  3: { 0: 0.0, 1: 1.0, 2: 3.1, 3: 10.4 },
  4: { 0: 0.0, 1: 1.0, 2: 3.1, 3: 10.4, 4: 0 },
  5: { 0: 0.0, 1: 1.0, 2: 3.1, 3: 10.4, 4: 7, 5: 9 },
  6: { 0: 0.0, 1: 1.0, 2: 3.1, 3: 10.4, 4: 7, 5: 9, 6: 0 },
  7: { 0: 0.0, 1: 1.0, 2: 3.1, 3: 10.4, 4: 7, 5: 9, 6: 0, 7: 9 },
  8: { 0: 0.0, 1: 1.0, 2: 3.1, 3: 10.4, 4: 7, 5: 9, 6: 0, 7: 0, 8: 4 },
};

const DynamicTableWithInput = ({ tableData, setSaveData, mode, max_value }) => {
  // State to hold the table data
  const [data, setData] = useState(initialData);
  const [rows, setRows] = useState({ rows: 3, columns: 3 });

  const [columnHeaders, setColumnHeaders] = useState([]);
  useEffect(() => {
    
    if (tableData) {
      
      setData(tableData);
      setRows({
        rows: Object.keys(tableData).length,
        columns: Object.keys(tableData).length,
      });
    } else {
      setData(initialData);
    }
  }, [tableData]);

  useEffect(() => {
    if (data) {
      // setData([]);
      var dataObj = {};
      const maxColumns = Math.max(
        ...Object.values(data).map((row) =>
          Math.max(...Object.keys(row).map(Number))
        )
      );

      // setColumnHeaders(Array.from({ length: maxColumns + 1 }, (_, i) => i))
      const rowsToGet = Array.from({ length: rows.rows }, (_, i) => i + 1); // First two rows
      const columnsToGet = Array.from(
        { length: rows.columns + 1 },
        (_, i) => i
      ); // First two columns

      const result = {};
      setColumnHeaders(columnsToGet);

      if (mode == "edit") {
        rowsToGet.forEach((row) => {
          result[row] = {};
          columnsToGet.forEach((column) => {
            if (tableData[row]) {
              if (tableData[row][column] !== undefined) {
                result[row][column] = tableData[row][column];
              } else {
                result[row][column] = "-";
              }
            } else {
              result[row][column] = "-";
            }
          });
        });
        console.log("result", result)
        setData(result);
      }
      if (mode == "create") {
        Array.from({ length: rows.rows }, (_, i) => i).map((i) => {
          var colDataObj = {};
          Array.from({ length: rows.columns + 1 }, (_, i) => i).map((j) => {
            if (j <= i + 1) {
              Object.assign(colDataObj, { [`${j}`]: j });
            } else {
              Object.assign(colDataObj, { [`${j}`]: "-" });
            }
          });

          Object.assign(dataObj, { [`${i + 1}`]: colDataObj });
        });

        console.log("result dataObj", dataObj)
        setData(dataObj);
      }
    }
  }, [rows.rows, rows.columns, mode]);

  const handleInputChange = (rowKey, colKey, value) => {

    if (Number(value) <= Number(max_value)) {
      const updatedData = {
        ...data,
        [rowKey]: {
          ...data[rowKey],
          [colKey]: parseFloat(value) || 0, // Convert input value to float, default to 0 if empty
        },
      };
      setData(updatedData);
    } else {
      const updatedData = {
        ...data,
        [rowKey]: {
          ...data[rowKey],
          [colKey]: parseFloat(max_value) || 0, // Convert input value to float, default to 0 if empty
        },
      };
      setData(updatedData);
    }
  };

  const handleDynamicRow = (e) => {
    const { name, value } = e.target;

    setRows((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleSave = () => {
    let removed = data;
    for (const key in removed) {
      for (const subKey in removed[key]) {
        if (removed[key][subKey] == "-" || removed[key][subKey] == undefined) {
          delete removed[key][subKey];
        }
      }
    }

   
    setSaveData(removed);
  };

  return (
    <div>
      <form className="dynamic-table d-flex justify-content-between w-100">
        <div className="d-flex flex-wrap my-2">
          <TextInput
            name={"rows"}
            title={"Rows"}
            type={"number"}
            value={rows.rows}
            handleChange={handleDynamicRow}
            placeholder={"Rows"}
          />
          <TextInput
            name={"columns"}
            title={"Columns"}
            type={"number"}
            value={rows.columns}
            handleChange={handleDynamicRow}
            placeholder={"Columns"}
          />
        </div>

        <Button name={"Save"} handleSubmit={handleSave} type={"submit"} />
      </form>

      {columnHeaders.length > 0 && data && (
        <table className="payout">
          <thead>
            <tr>
              <th className="fs-10  table-input">Selections (n)</th>{" "}
              {/* Empty cell for top-left corner */}
              {columnHeaders.map((header) => (
                <th className="fs-12 table-input" key={header}>
                  {header} Hits
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="payout">
          {console.log("data ==>><<==", data)}
            {Object.keys(data).map((rowKey) => (
              <tr key={rowKey}>
                <th className="fs-12 table-input">{rowKey}</th>{" "}
                {columnHeaders.map((colKey) => (
                  <td className="table-input" key={colKey}>
                   
                    <div class="input-group mb-1">
                      <input
                        type="number"
                        className="form-control form-control-solid table-input p-1"
                        value={
                          data[rowKey][colKey] !== undefined
                            ? data[rowKey][colKey]
                            : "-"
                        }
                        onChange={(e) =>
                          handleInputChange(rowKey, colKey, e.target.value)
                        }
                        onKeyDown={(e) => {
                          // if (e.charCode !== 46) {
                          //   if (e.key.match(/^[+-]?([0-9]*[.])?[0-9]+$/) === null) {
                          //     console.log(e.charCode, "if");
                          //     // e.preventDefault();
                          //   } else {
                          //     console.log("else");
                          //   }
                          // } else {
                          // }
                        }}
                        disabled={
                          data[rowKey][colKey] === undefined ||
                          data[rowKey][colKey] == "-"
                            ? true
                            : false
                        }
                      />
                      {/* <div class="input-group-append"> */}
                      <span class="input-group-text py-1 px-2 fs-12">x</span>
                      {/* </div> */}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

DynamicTableWithInput.prototype = {
  tableData: PropTypes.any,
  setSaveData: PropTypes.func,
  mode: PropTypes.string,
  max_value: PropTypes.number,
};
export default DynamicTableWithInput;
