import React, { createContext, useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import Header from "../pages/layout/header";
import HumburgerMenu from "../pages/layout/sidebar";
import { sidebarDrawerInit, getCookies } from "../helper/utils";

export const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const PrivateRoute = ({ path, element, Component }) => {
  
  const auth = getCookies("token");
  const [menuOpen, setMenuOpen] = useState(sidebarDrawerInit ? false : true);

  const [toggleShow, setToggleShow] = useState(true);
  const { innerWidth: width, innerHeight: height } = window;

  const drawerWidth = "260px";

  let forSmallScreen = menuOpen ? drawerWidth : "0px";
  let forLargeScrren = menuOpen ? drawerWidth : "70px";

  let newMargin = width <= 768 ? forSmallScreen : forLargeScrren;

  const [theme, setTheme] = useState("light");

  // On mount, check for a saved theme in localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    console.log(31,{savedTheme} )
    if (savedTheme) {
      setTheme(savedTheme);
      document.documentElement.setAttribute("data-theme", savedTheme);
    }
  }, []);

  

  // Switch the theme and store it in localStorage
  const toggleTheme = (data) => {
    console.log("theme", theme, data)
    const newTheme = data === "light" ? "dark" : "light";
    setTheme(data);
    document.documentElement.setAttribute("data-theme", newTheme);
    localStorage.setItem("theme", data); // Store theme in localStorage
  };
  useEffect(() => {
    const root = document.documentElement;
    if (theme === 'dark') {
      root.classList.add('dark-theme');
      root.classList.remove('light-theme');
    } else {
      root.classList.add('light-theme');
      root.classList.remove('dark-theme');
    }
}, [theme]);


  return  auth ? (
    <div className="wrapper">
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <div className={"page-wrap"}>
        
          <HumburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />

          <div
            style={{
              marginLeft: newMargin,
              transition: "all 0.5s ease",
            }}
          >
          
            {element}
          </div>
        </div>
      </ThemeContext.Provider>

     
    </div>
  ) : (
    
    <Navigate to="/" />
  );
};

export default PrivateRoute;
