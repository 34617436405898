import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const deleteGame = createAsyncThunk("user/deleteGame", async (payload) => {

  const response = await api.post("admin/games/delete", payload);
 
  return response.data;
});

const deleteGameSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGame.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default deleteGameSlice.reducer;
