import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../helper/api";
import { toast } from "react-toastify";

export const signup = createAsyncThunk("user/signup", async (payload) => {
  console.log(7, payload)
  const response = await api.post("admin/create", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
});

const signUpSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["payload.headers"],
      },
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.loading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default signUpSlice.reducer;
