import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useOutsideAlerter,
  handleSidebarDrawerInit,
  superadminsideBarList,
  adminsideBarList,
  super_user_location_access,
  roles,
} from "../../helper/utils";
import { getProfile } from "../../redux/slices/getProfileSlice";

import { useTheme } from "../../routes/private-route";
import { deleteCookies } from "../../helper/utils";

import Left from "../../assets/images/svgs/left.svg";
import Right from "../../assets/images/svgs/right.svg";
import User from "../../assets/images/user.jpg";
import LightTheme from "../../assets/images/svgs/light-theme.svg";
import DarkTheme from "../../assets/images/svgs/dark-theme.svg";

const HumburgerMenu = ({ menuOpen, setMenuOpen }) => {
  const { theme, toggleTheme } = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { data: loggedInUser } = useSelector((state) => state.getProfile);

  const [currentPage, setCurrentPage] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [hoverProfile, setHoverProfile] = useState(false);
  const [hoverTheme, setHoverTheme] = useState(false);

  const [sideBarList, setSideBarList] = useState([]);
  const [open, setOpen] = useState(menuOpen);
  const [openLogout, setOpenLogout] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;

  useOutsideAlerter(wrapperRef, open, setOpen);

  useEffect(() => {
    getProfileData();
  }, []);
  useEffect(() => {
    console.log(45, { loggedInUser });
    if (loggedInUser) {
      if (loggedInUser.role == "super_admin") {
        setSideBarList(superadminsideBarList);
      } else if (loggedInUser.role == "admin") {
        setSideBarList(adminsideBarList);
      }
    } else {
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (width <= 990) {
      setMenuOpen(!open);
    } else {
      setMenuOpen(open);
    }
  }, [open]);

  const handleLogout = () => {
    console.log(62, "delete");
    deleteCookies("token");
    deleteCookies("role");
    setOpen(false);
    setOpenLogout(true);
    navigate("/");
  };

  const handleDrawerOpen = (event) => {
    event.preventDefault();
    setOpen(!open);
    handleSidebarDrawerInit();
  };

  const handleSidebarClick = (e) => {
    console.log(e);

    navigate(`/${e.url}`);
    setCurrentPage(e.label);
  };
  const getProfileData = () => {
    dispatch(getProfile())
      .unwrap()
      .then((res) => {
        console.log(64, res);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  };

  return (
    <div id="wrapper" className={`wrapper ${isToggled ? "toggled" : ""}`}>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <h2>KENO</h2>
        </div>
        <ul className="sidebar-nav">
          {sideBarList?.map((item, index) => (
            <li
              className={
                item.label == currentPage ? `active` : `` + `cursor-pointer`
              }
              key={index}
              onClick={() => {
                handleSidebarClick(item);
              }}
            >
              <div>
                <span className="svg-icon svg-icon-2 ">{item.img}</span>

                <span className="menu-title ms-3 fs-14">{item.label}</span>
              </div>
            </li>
          ))}
          {loggedInUser?.email == "locationadmin@gmail.com" &&
            super_user_location_access.map((item, index) => (
              <li
                className={
                  item.label == currentPage ? `active` : `` + `cursor-pointer`
                }
                key={index}
                onClick={() => {
                  handleSidebarClick(item);
                }}
              >
                <div>
                  <span className="svg-icon svg-icon-2 ">{item.img}</span>

                  <span className="menu-title ms-3 fs-14">{item.label}</span>
                </div>
              </li>
            ))}
        </ul>
      </aside>

      <div id="navbar-wrapper">
        <nav className="navbar navbar-inverse">
          <div className="container-fluid">
            <div
              className="navbar-header"
              onClick={(e) => {
                // e.preventDefault();
                setIsToggled((prevState) => !prevState);
                handleDrawerOpen(e);
              }}
              handleDrawerOpen
            >
              <span className="svg-icon svg-icon-2 sidebar-arrow text-center">
                <img
                  src={Left}
                  style={{
                    transform: open ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              </span>

              <a href="#" className="navbar-brand" id="sidebar-toggle">
                <i className="fa fa-bars"></i>
              </a>
            </div>
            <div className="app-navbar cursor-pointer flex-shrink-0">
              <div className="app-navbar-item ms-1 ms-md-3">
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  onMouseOver={() => {
                    setHoverProfile(true);
                    setHoverTheme(false);
                  }}
                >
                  <img src={loggedInUser?.profile_pic || User} alt="" />
                </div>
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  onMouseOver={() => {
                    setHoverTheme(true);
                    setHoverProfile(false);
                  }}
                >
                  <img src={LightTheme} />
                </div>

                {hoverProfile && (
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-gray-800  py-4 fs-6"
                    onMouseOver={() => {
                      setHoverProfile(true);
                    }}
                    onMouseOut={() => {
                      setHoverProfile(false);
                    }}
                    onClick={(e) => {
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    <div className="menu-item px-3 cursor-none">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="d-flex flex-wrap">
                          <div className=" symbol symbol-30px symbol-md-40px">
                            <img
                              src={loggedInUser?.profile_pic || User}
                              alt=""
                            />
                          </div>
                          <div className="fw-bold align-items-center mx-3">
                            <span className="fs-14">{loggedInUser?.name} </span>

                            <div className="fw-semibold text-muted text-hover-primary fs-12">
                              {loggedInUser?.email}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="separator my-2"></div>

                    <div
                      className="menu-item px-4 py-2 mx-2"
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <span className="menu-link  fs-14 px-2">My Profile</span>
                    </div>

                    <div className="separator my-1"></div>

                    <div className="menu-item px-4 py-2 mx-2">
                      <span
                        className="menu-link  fs-14 px-2"
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        Sign Out
                      </span>
                    </div>
                  </div>
                )}
                {hoverTheme && (
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-gray-800  py-4 fs-6"
                    onMouseOver={() => {
                      setHoverTheme(true);
                    }}
                    onMouseOut={() => {
                      setHoverTheme(false);
                    }}
                    onClick={(e) => {
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    <div className="separator my-2"></div>

                    <div
                      className="menu-item px-4 py-2 mx-2"
                      onClick={() => {
                        toggleTheme("light");
                      }}
                    >
                      <span className="menu-link  fs-14 px-2">Light</span>
                    </div>

                    <div className="separator my-1"></div>

                    <div className="menu-item px-4 py-2 mx-2">
                      <span
                        className="menu-link  fs-14 px-2"
                        onClick={() => {
                          toggleTheme("dark");
                        }}
                      >
                        Dark
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

HumburgerMenu.prototype = {
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
};

export default HumburgerMenu;
