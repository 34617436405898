import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Radio } from "../components";

import User from "../assets/images/user.jpg";
import Edit from "../assets/images/svgs/edit.svg";
import Delete from "../assets/images/svgs/delete.svg";
import Download from "../assets/images/svgs/download.svg";

import Previous from "../assets/images/svgs/left.svg";
import Next from "../assets/images/svgs/right.svg";

const Table = ({
  loading,
  columns,
  rows,
  setRows,
  handleAction,
  skip,
  setSkip,
  total,
  handleStatus,
  downloadAction,
  roleWise,
  handleRoleWise,
  role
}) => {
  const limit = 10;

  const totalPages = Math.ceil(total / limit);
  const maxVisibleButtons = 5; // Number of page buttons to show at once
  const [currentPage, setCurrentPage] = useState(skip);

  // Determine the range of visible page buttons
  const getVisiblePageNumbers = () => {
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisibleButtons / 2)
    );

    const endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);
    console.log({ currentPage, startPage, endPage });
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  // const handlePageClick = (page) => {

  //   setSkip(Number(page));
  //   setCurrentPage(Number(page));
  // };

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNum) => {
    setSkip(Number(pageNum));
    setCurrentPage(pageNum);
  };

  // const handlePageClick = (page) => {
  //   setCurrentPage(Number(page));
  // };

  const handleChangeRadio = (data) => {
    setRows((prevItems) =>
      prevItems.map((item) =>
        item._id === data._id ? { ...item, status: !data.status } : item
      )
    );
    handleStatus(data, !data.status);
  };

  return (
    <div className="card m-3 mb-xl-8">
      <div className="card-header border-0 pt-2 d-flex flex-wrap justify-content-sm-start justify-content-md-between ">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-14 mb-1">
            Members Statistics
          </span>
        </h3>
        {roleWise && <div className="d-flex">
          {" "}
          <span>User</span>
          <Radio
            name={""}
            title={""}
            type={""}
            value={role}
            error={""}
            handleChange={handleRoleWise}
            placeholder={""}
          />
          <span>Admin</span>
        </div>}
        
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold text-muted">
                <th className="w-25px fs-14 cursor-pointer">Sr. No</th>
                {columns?.map((itm, index) => (
                  <th key={index} className="w-25px fs-14 cursor-pointer">
                    {itm.name}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {console.log("handleAction here", rows)}
              {!loading && rows.length > 0 ? (
                <>
                  {rows?.map((itm, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {columns?.map((i, index1) => (
                        <td key={index1}>
                          {i.type === "text" && (
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <p className="mb-0  text-capitalize fw-bold text-hover-primary fs-12">
                                  {itm[i.name]}
                                </p>
                              </div>
                            </div>
                          )}
                          {i.type === "email" && (
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <p className="mb-0   fw-bold text-hover-primary fs-12">
                                  {itm[i.name]}
                                </p>
                              </div>
                            </div>
                          )}
                          {i.type === "text-profile" && (
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-45px me-2">
                                <img
                                  src={
                                    itm?.profile_pic ? itm?.profile_pic : User
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="d-flex justify-content-start flex-column">
                                <p className="mb-0   text-capitalize fw-bold text-hover-primary fs-12">
                                  {itm[i.name]}
                                </p>

                                {/* <span className="text-muted fw-semibold text-muted d-block fs-12">
                              HTML, JS, ReactJS
                            </span> */}
                              </div>
                            </div>
                          )}
                          {i.type === "checkbox" && (
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input widget-9-check"
                                type="checkbox"
                                value="1"
                              />
                            </div>
                          )}
                          {i.type === "progress" && (
                            <div className="d-flex flex-column w-100 me-2">
                              <div className="d-flex flex-stack mb-2">
                                <span className="text-muted me-2 fs-12 fw-bold">
                                  {itm.Progress}%
                                </span>
                              </div>

                              <div className="progress h-6px w-100">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{ width: `${itm.Progress}%` }}
                                  aria-valuenow={itm.Progress}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </div>
                          )}
                          {i.type === "button" && (
                            <div className="d-flex justify-content-center flex-shrink-0">
                              <p
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                onClick={() => handleAction("edit", itm)}
                              >
                                <span className="svg-icon svg-icon-3">
                                  <img src={Edit} alt="edit" />
                                </span>
                              </p>

                              <p
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                onClick={() => handleAction("delete", itm)}
                              >
                                <span className="svg-icon svg-icon-3">
                                  <img src={Delete} alt="delete" />
                                </span>
                              </p>
                              {downloadAction && (
                                <p
                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                  onClick={() => handleAction("download", itm)}
                                >
                                  <span className="svg-icon svg-icon-3">
                                    <img src={Download} alt="download" />
                                  </span>
                                </p>
                              )}
                            </div>
                          )}
                          {i.type === "radio" && (
                            <Radio
                              name={""}
                              title={""}
                              type={""}
                              value={itm.status}
                              error={""}
                              handleChange={() => handleChangeRadio(itm)}
                              placeholder={""}
                            />
                          )}
                          {i.type === "date" && (
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <p className="mb-0   text-capitalize fw-bold text-hover-primary fs-12">
                                  {console.log(228, itm[i.name])}
                                  {moment(itm[i.name]).format(
                                    "YYYY-MM-DD"
                                  )}{" "}
                                  {"    "}
                                  {moment(itm[i.name]).format("HH:mm")}{" "}
                                  {moment(itm[i.name]).format("A")}
                                </p>
                              </div>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : loading ? (
                <tr>
                  <td colSpan={columns.length + 1} className="text-center">
                    <span className="indicator-progress text-center">
                      {" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </td>
                </tr>
              ) : (
                <tr className="text-center">
                  <td colSpan={6}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="d-flex flex-stack flex-wrap pt-10 justify-content-center justify-content-md-center justify-content-lg-between">
            <div className="fs-14 fw-semibold text-gray-700">
              {/* Showing {currentPage} to {currentPage + limit} of {total} entries */}
            </div>

            <ul className="pagination">
              <li
                className="page-item previous"
                onClick={() => {
                  handlePrev();
                  // if (currentPage > 1) {
                  //   setCurrentPage(currentPage - 1);
                  setSkip(currentPage - 1);
                  // }
                }}
              >
                <div className="page-link cursor-pointer">
                  <img src={Previous} alt={"previous"} />
                </div>
              </li>

              {/* Ellipsis on the left if not showing the first page */}
              {currentPage > Math.floor(maxVisibleButtons / 2) + 1 && (
                <span>...</span>
              )}

              {getVisiblePageNumbers().map((pageNum, index) => (
                <li
                  key={index}
                  className={
                    currentPage === pageNum ? ` active page-item` : " page-item"
                  }
                  onClick={() => {
                    handlePageClick(pageNum);
                  }}
                >
                  <div className="page-link cursor-pointer">{pageNum}</div>
                </li>
              ))}

              {/* Ellipsis on the right if not showing the last page */}
              {currentPage < totalPages - Math.floor(maxVisibleButtons / 2) && (
                <span>...</span>
              )}

              {/* {Array.from({ length: totalPages }, (_, i) => i + 1)?.map(
                (page, index) => (
                  <li
                    key={index}
                    className={
                      currentPage === page + 1
                        ? ` active page-item`
                        : " page-item"
                    }
                    onClick={() => {
                      handlePageClick(page + 1);
                    }}
                  >
                    <div className="page-link cursor-pointer">{page + 1}</div>
                  </li>
                )
              )} */}

              <li
                className="page-item next"
                onClick={() => {
                  handleNext();
                  // if (currentPage < total / limit) {
                  setSkip(currentPage + 1);
                  //   setCurrentPage(currentPage + 1);
                  // }
                }}
              >
                <div className="page-link cursor-pointer">
                  <img src={Next} style={{ transform: "rotate(180deg)" }} />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.prototype = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  rowd: PropTypes.array,
  setRows: PropTypes.func,
  handleAction: PropTypes.func,
  handleStatus: PropTypes.func,
  downloadAction: PropTypes.func,
};

export default Table;
