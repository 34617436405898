import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const getAllAdmin = createAsyncThunk(
  "user/getAllAdmin",
  async (payload) => {
    const response = await api.post("admin/get-all", payload);

    return response.data;
  }
);

const getAllAdminSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["payload.headers"],
      },
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default getAllAdminSlice.reducer;
