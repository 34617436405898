import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast } from "react-toastify";

export const downloadInvoice = createAsyncThunk(
  "user/downloadInvoice",
  async (payload) => {
    console.log(8, payload);
    const response = await api.post(
      "/admin/commission/download-commission-invoice",
      {
        game_id: "67319e64d3c4fd787ffc700c",
        admin_id: "67319e28d3c4fd787ffc6fed",
      },
      {
        responseType: 'blob', // Ensure the response is treated as a Blob
    }
     
    );

    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    console.log(24, url)
    const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'example.pdf'; // Set the desired file name
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

    return response;
  }
);

const downloadInvoiceSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["payload.headers"],
      },
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadInvoice.fulfilled, (state, action) => {
        state.loading = false;
        console.log(44, action)
        // state.data = action.payload;
        // toast.success(action.payload.message);
      })
      .addCase(downloadInvoice.rejected, (state, action) => {
        state.loading = false;
        console.log(50, action)
        // state.error = action.error.message;
        // toast.error(action.error.message);
      });
  },
});

export default downloadInvoiceSlice.reducer;
