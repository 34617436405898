import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../../helper/api";
import { toast  } from "react-toastify";

export const updateGame = createAsyncThunk("user/updateGame", async (payload) => {

  const response = await api.put("admin/games/update", payload);
 
  return response.data;
});

const updateGameSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGame.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
        console.log(36, action.payload)
        toast.success(action.payload.message)
      })
      .addCase(updateGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default updateGameSlice.reducer;
