import React, { useState } from "react";
import PropTypes from "prop-types";

const PasswordInput = ({
  name,
  title,
  type,
  value,
  error,
  handleChange,
  placeholder,
  className,
}) => {
  const [visibility, setVisibility] = useState({ [`${name}`]: "" });

  return (
    <div className=" mx-2 mb-2 ">
      {title && (
        <label className="d-flex align-items-center fs-12 fw-semibold mb-2">
          <span className="required">{title}</span>
        </label>
      )}

      <div className="password-container">
        <input
          id="password"
          type={visibility[name] ? "text" : "password"}
          className={
            className
              ? className
              : "form-control password-input form-control-solid"
          }
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <span
          className="toggle-icon"
          onClick={() => {
            setVisibility((prev) => ({ ...prev, [name]: !visibility[name] }));
          }}
        >
          {visibility[name] ? (
            <i className="bi bi-eye-fill"></i>
          ) : (
            <i className="bi bi-eye-slash-fill"></i>
          )}
        </span>
      </div>

      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

PasswordInput.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default PasswordInput;
