import React from "react";
import PropTypes from "prop-types";

const NumberInput = ({
  name,
  title,
  type,
  value,
  error,
  handleChange,
  placeholder,
  className,
}) => {


  const handleKeyPress = (e) => {
    console.log(31, e.key, e.charCode);
    if (type == "number") {
      console.log(33,)
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "."];

      if (allowedKeys.includes(e.key)) {
        // Allow navigation, deletion, and special keys
        return true;
      }

      // Check if the key pressed is a number or a negative sign at the start
      const regex = /^-?[0-9]*\.?[0-9]*$/;
      const inputValue = e.target.value + e.key;

      if (!regex.test(inputValue) || e.key == '-') {
        e.preventDefault();
      }

      // pattern="^[+-]?([0-9]*[.])?[0-9]+$",  ^-?\d+$

      // if (e.charCode !== 46) {
      //   console.log(e.key.match(/^-?[0-9]*\.?[0-9]+$/))
      //   if (e.key.match(/^-?[0-9]*\.?[0-9]+$/) === null) {

      //     e.preventDefault();
      //   } else {

      //   }
      // } else {
      // }
    } else if (type == "number" && e.key !== '-') {
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "."];

      if (allowedKeys.includes(e.key)) {
        console.log(64)
        // Allow navigation, deletion, and special keys
        return true;
      }

      // Check if the key pressed is a number or a negative sign at the start
      const regex = /^-?[0-9]*\.?[0-9]*$/;
      const inputValue = e.target.value + e.key;

      if (!regex.test(inputValue)) {
        console.log(73)
        e.preventDefault();
      } else {
        console.log(76)
        
      }

     
    } else {
      console.log(84)
      e.preventDefault();
      return false
    }
  };
 
  return (
    <div className=" mx-2 mb-2">
    {title &&  <label className="d-flex align-items-center fs-12 fw-semibold mb-2">
      <span className="required">{title}</span>
    </label> }
   

    <input
      type={'number'}
      className={className ? className : "form-control  form-control-solid"}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
   
        handleChange(e);
      }}
      onKeyDown={handleKeyPress}
      // onKeyDown={(e) => {
      //   console.log(34, e.charCode, e.key)
      //   if (e.charCode !== 46 && e.charCode !== 0) {
      //     if (e.key.match(/^[+-]?([0-9]*[.])?[0-9]+$/) === null) {
      //       console.log(e.charCode, "if");
      //       e.preventDefault();
      //     } else {
      //       console.log("else");
      //     }
      //   } else {
      //   }
      
      // }}
    />
    <span className="fs-10 text-danger">{error}</span>
  </div>
  );
};

NumberInput.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
export default NumberInput;
