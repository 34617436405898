import validator from "validator";

// Password regex
// const passwordRegex = new RegExp(
//   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
// );
const patternPwd =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const loginValidation = (data) => {
  let errors = {};
  if (validator.isEmpty(data?.email.trim()))
    errors.email = "Please enter email address";

  if (data?.email && !validator.isEmail(data.email))
    errors.email = "Email address must be valid";

  if (validator.isEmpty(data?.password.trim()))
    errors.password = "Please enter password";
  else if (!patternPwd.test(data?.password))
    errors.password =
      "Password should be at least 8 characters with upper case, number and special character";

  return { errors, isValid: Object.keys(errors).length <= 0 };
};

export const signupValidation = (data) => {
  let errors = {};
  console.log(data)

  if (validator.isEmpty(data?.name.trim())) errors.name = "Please enter name";

  if (data?.email && !validator.isEmail(data.email))
    errors.email = "Email address must be valid";

  if (validator.isEmpty(data?.email.trim()))
    errors.email = "Please enter email address";

  if (data?.email && !validator.isEmail(data.email))
    errors.email = "Email address must be valid";

  if (validator.isEmpty(data?.password.trim()))
    errors.password = "Please enter password";
  else if (!patternPwd.test(data?.password))
    errors.password =
      "Password should be at least 8 characters with upper case, number and special character";

  const isValidPhoneNumber = validator.isMobilePhone(data.mobile);
  console.log({isValidPhoneNumber})
  if (validator.isEmpty(data?.mobile.trim()))
    errors.mobile = "Please enter mobile number";
  else if (!isValidPhoneNumber)
    errors.mobile = "Please enter valid mobile number";

  return { errors, isValid: Object.keys(errors).length <= 0 };
};

export const forgotPasswordValidation = (data) => {
  let errors = {};


  if (validator.isEmpty(data?.oldPassword.trim()))
    errors.oldPassword = "Please enter password";
  else if (!patternPwd.test(data?.oldPassword))
    errors.oldPassword =
      "Old Password should be at least 8 characters with upper case, number and special character";

  if (validator.isEmpty(data?.newPassword.trim()))
    errors.newPassword = "Please enter password";
  else if (!patternPwd.test(data?.newPassword))
    errors.newPassword =
      "New Password should be at least 8 characters with upper case, number and special character";

  if (data.oldPassword === data.newPassword) {
    errors.newPassword = "Old Password and New Password should not be same.";
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};



export const resetPasswordValidation = (data) => {
  let errors = {};
  if (validator.isEmpty(data?.password?.trim()))
    errors.password = "Please enter new password";

  if (!validator.isEmpty(data?.password?.trim()) && data?.password?.length < 6)
    errors.password = "Password must be at least 6 characters";

  return { errors, isValid: Object.keys(errors).length <= 0 };
};
