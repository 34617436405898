
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../helper/api";

export const getClientList = createAsyncThunk("user/getClientList", async (payload) => {
  console.log("admin/list")
  const response = await api.post("admin/list", payload, {
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    }
  });
  return response.data;
});


const getClientListSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClientList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getClientList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default getClientListSlice.reducer;
