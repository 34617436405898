import React, { useState } from "react";
import PropTypes from "prop-types";

import Cross from "../assets/images/svgs/cross.svg";

const Modal = ({ open, setOpen }) => {
 

  return (
    <div className={open ? `modal d-block` : `modal`}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header py-3 d-flex justify-content-between">
            <span className="fs-14">Title</span>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span
                className="svg-icon svg-icon-1"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <img src={Cross} alt={"cross"} />
              </span>
            </div>
          </div>

          <div className="modal-body scroll-y m-5"></div>
        </div>
      </div>
    </div>
  );
};

Modal.prototype = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
export default Modal;
