import React, {  useState } from "react";
import PropTypes from "prop-types";

import HumburgerMenu from "./sidebar";

const Header = ({ style }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggleShow, setToggleShow] = useState(true);

 
  return (
    
    <HumburgerMenu menuOpen={menuOpen} setToggleShow={setToggleShow} /> 
  )
    
};

Header.prototype = {
  style: PropTypes.any,
};

export default Header;
