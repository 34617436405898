import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../helper/api";
import { toast } from "react-toastify";

export const userLocationList = createAsyncThunk("user/userLocationList", async (payload) => {
  const response = await api.post("user/user-location-list", payload);
  return response.data;
});

const userLocationListSlice = createSlice({
  name: "user",
  initialState: {
    data:null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLocationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLocationList.fulfilled, (state, action) => {
   
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(userLocationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default userLocationListSlice.reducer;
