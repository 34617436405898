
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../helper/api";

export const getSettingList = createAsyncThunk("user/getSettingList", async (payload) => {
  console.log("admin/setting/list")
  const response = await api.post("admin/setting/list", payload, {
    Headers: {
    
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  return response.data;
});


const getSettingListSlice = createSlice({
  name: "user/getSettingList",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettingList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getSettingList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default getSettingListSlice.reducer;
