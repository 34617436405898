
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import api from "../../../helper/api";

export const createSetting = createAsyncThunk("user/createSetting", async (payload) => {
    const response = await api.post("admin/setting/create", payload);
 
    return response.data;
});


const createSettingSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        toast.success(action.payload.message);
      })
      .addCase(createSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default createSettingSlice.reducer;
