import React from "react";
import PropTypes from "prop-types";

const Select = ({
  name,
  title,
  value,
  error,
  options,
  handleChange,
  placeholder,
  className,
}) => {

  
  return (
    <div className=" mx-2 mb-2">
      {title && (
        <label className="d-flex align-items-center fs-12 fw-semibold mb-2">
          <span className="required">{title}</span>
        </label>
      )}
      <select
        name={name}
        value={value}
        aria-label={`Select a ${placeholder}`}
        data-control="select2"
        data-placeholder={`Select a ${placeholder}..`}
        className=" form-select-solid form-select "
        onChange={(e) => {
          // console.log(29, e.target)
          handleChange(e)
        }}
      >
        <option value="">Select a {placeholder}..</option>
        {options?.map((item, index) => (
          <option key={index} value={item.value} 
          // onClick={(e) => {
           
          //   const { name, value } = e.target;
          //   console.log(name, value, item, e)
          // }}
          >
            <b>{item.label}</b>
          </option>
        ))}
      </select>
      <span className="fs-10 text-danger">{error}</span>
    </div>
  );
};

Select.prototype = {
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default Select;
