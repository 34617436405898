import axios from "axios";
import { getCookies, deleteCookies } from "./utils";
import { toast } from "react-toastify";

export const apiURL = 'https://kenob.staging-server.in/' // "http://192.168.2.59:8000/" //"https://kenob.staging-server.in/" //;

export const recaptchaKey = "";

export const api = axios.create({
  baseURL: apiURL,
});

api.defaults.headers = {
  "Access-Control-Allow-Origin": `*`,
  "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,PATCH,OPTIONS",
  "Content-Type": "application/json; charset=utf-8",
  Accept: "*/*",
};

api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getCookies("token")}`;
    // config.headers["Content-Type"] = "application/json; charset=utf-8"
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log("error", response.status)
    if (response?.status === 401) {
      deleteCookies("token");
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    }
    return response;
  },
  function (error) {
    

    console.log("error 44", error.response)
    if (error?.response?.data.code === 401) {
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
       return Promise.reject(error);
    } else if(error.response.data.code == 400) {
      toast.error(error.response.data.message)
    } else if(error.response.status == 404) {
      toast.error(error.response.data.error)
    }
     return Promise.reject(error);
  }
);

export default api;
