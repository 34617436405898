import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { login } from "../../redux/slices/loginSlice";

import TextInput from "../../components/text-input";
import { PasswordInput } from "../../components";
import Button from "../../components/button";
import { loginValidation } from "../../validation/signup";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});

  const [form, setForm] = useState({ email: "", password: "" });
  const { data } = useSelector((state) => state.login);

  // if (window.top !== window.self) {
  //   console.log("This page is in an iframe. ooo");
  //   console.log("ooo Parent URL:", document.referrer);
  // } else {
  //   console.log("ooo This page is not in an iframe.");
  // }


  const handleSubmit = (e) => {
    e.preventDefault();
    const { errors, isValid } = loginValidation(form);

    if (!isValid) {
      setError(errors);
    } else {
      dispatch(login(form))
        .unwrap()
        .then(() => {
          navigate("/home-screen", { replace: true });
        })
        .catch((error) => {
          console.error("Failed to fetch user:", error);
        });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setForm((prev) => ({ ...prev, [`${name}`]: value }));
    setError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <div className="center-div shadow">
      <form>
        <TextInput
          name={"email"}
          title={"Email"}
          type={"text"}
          value={form.email}
          error={error.email}
          handleChange={handleChange}
          placeholder={"Email"}
        />

        <PasswordInput
          name={"password"}
          title={"password"}
          type={"password"}
          value={form.password}
          error={error.password}
          handleChange={handleChange}
          placeholder={"Password"}
        />

        <div className="text-end fs-12 cursor-pointer fw-semibold my-2">
          <div
            className="link-primary cursor-pointer"
            onClick={() => {
              navigate("forgot-password");
            }}
          >
            Forgot Password?
          </div>
        </div>

        <Button name={"Sign In"} handleSubmit={handleSubmit} type={"submit"} />

        <div className="text-gray-500 text-center fw-semibold fs-12">
          Not a Member yet?{" "}
          <span
            className="link-primary cursor-pointer"
            onClick={() => {
              navigate("signup");
            }}
          >
            Sign up
          </span>
        </div>
      </form>
     
    </div>
  );
};
export default Login;
