
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../../helper/api";

export const updateSetting = createAsyncThunk("user/updateSetting", async (payload) => {
    const response = await api.put("admin/setting/update", payload);
 
    return response.data;
});


const updateSettingSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ["payload.headers"],
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        console.log(action.payload.data)
        toast.success(action.payload.message);
      })
      .addCase(updateSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(action.error.message);
      });
  },
});

export default updateSettingSlice.reducer;
