import React from "react";
import PropTypes from "prop-types";

const Button = ({name, handleSubmit, type, disabled}) => {
  return (
    <div className="d-grid my-3">
      <button
        type="submit"
     
        className={type === 'submit' ? 'btn-primary btn' : 'btn-secondary btn'}
        onClick={(e) => {
          e.preventDefault()
          handleSubmit(e);
        }}
        disabled={disabled}
      >
        <span className="indicator-label">{name}</span>

        {/* <span className="indicator-progress">
          {" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span> */}
      </button>
    </div>
  );
};

Button.prototype = {
  name: PropTypes.string,
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
