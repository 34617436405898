import { configureStore } from "@reduxjs/toolkit";
import todoListSlice from "./slices/todoListSlice";
import loginSlice from "./slices/loginSlice";
import signUpSlice from "./slices/signupSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import getProfileSlice from "./slices/getProfileSlice";

import getClientListSlice from "./slices/clients/getClientListSlice";
import updateClientSlice from "./slices/clients/updateClientSlice";

import updateProfileSlice from "./slices/updateProfileSlice";
import getSettingListSlice from "./slices/settings/getSettingListSlice";
import createSettingSlice from "./slices/settings/createSettingSlice";
import updateSettingSlice from "./slices/settings/updateSettingSlice";

import createGameSlice from "./slices/game-management/createGameSlice";
import getGameSlice from "./slices/game-management/getGameSlice";
import listGameSlice from "./slices/game-management/listGameSlice";
import updateGameSlice from "./slices/game-management/updateGameSlice";
import deleteGameSlice from "./slices/game-management/deleteGameSlice";

import createPayoutSlice from "./slices/payout-adjustment/createPayoutSlice";
import listPayoutSlice from "./slices/payout-adjustment/listPayoutSlice";
import updatePayoutSlice from "./slices/payout-adjustment/updatePayoutSlice";

import getCommissionSlice from "./slices/commission/getCommissionSlice";
import listCommissionSlice from "./slices/commission/listCommissionSlice";
import getAdminCommissionSlice from "./slices/commission/getAdminCommissionSlice";
import createCommissionSlice from "./slices/commission/createCommissionSlice";
import updateCommisionSlice from "./slices/commission/updateCommisionSlice";
import deleteCommissionSlice from "./slices/commission/deleteCommissionSlice";

import getAllAdminSlice from "./slices/common/getAllAdminSlice";
import getAllGameSlice from "./slices/common/getAllGameSlice";
import adminGameHistorySlice from "./slices/game-management/adminGameHistorySlice";
import adminCommissionSlice from "./slices/commission/adminCommissionSlice";
import downloadInvoiceSlice from "./slices/commission/downloadInvoiceSlice";
import userLocationSlice from "./slices/userLocationSlice";
import userLocationListSlice from "./slices/userLocationListSlice";

const store = configureStore({
  reducer: {
    todoList: todoListSlice,
    login: loginSlice,
    signup: signUpSlice,
    forgotPassword: forgotPasswordSlice,
    getProfile: getProfileSlice,
    getClients: getClientListSlice,
    updateClient: updateClientSlice,
    updateProfile: updateProfileSlice,
    getSetting: getSettingListSlice,
    createSetting: createSettingSlice,
    updateSetting: updateSettingSlice,

    createGame: createGameSlice,
    getGame: getGameSlice,
    listGame: listGameSlice,
    updateGame: updateGameSlice,
    deleteGame: deleteGameSlice,

    createPayout: createPayoutSlice,
    listPayout: listPayoutSlice,
    updatePayout: updatePayoutSlice,

    getCommission: getCommissionSlice,
    listCommission: listCommissionSlice,
    updateCommision: updateCommisionSlice,
    createCommission: createCommissionSlice,
    deleteCommission: deleteCommissionSlice,
    getAdminCommission: getAdminCommissionSlice,
    adminCommission: adminCommissionSlice,
    downloadInvoice: downloadInvoiceSlice,

    getAllGame: getAllGameSlice,
    getAllAdmin: getAllAdminSlice,

    adminGameHistory: adminGameHistorySlice,
    userLocation: userLocationSlice,
    userLocationList: userLocationListSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        serializableCheck: false,
        ignoredPaths: ["payload.headers"],
      },
    }),
});

export default store;
